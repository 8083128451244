import React, { Fragment } from "react"
import Input from "../../components/Input"
import S from "./styled"
import { FormRow1, FormRow2, FormRow3 } from "../../styles/styled"

S.FormRow1 = FormRow1
S.FormRow2 = FormRow2
S.FormRow3 = FormRow3

const Shipping = ({ update, errors }) => {
  return (
    <Fragment>
      <S.H2>Shipping Address</S.H2>

      <S.FormRow2>
        <Input
          name="address"
          label="Address"
          reqd={true}
          autocomplete="street-address"
          update={update}
          errors={errors}
        />

        <Input
          name="address2"
          label="Address 2"
          reqd={true}
          autocomplete=""
          update={update}
          errors={errors}
        />
      </S.FormRow2>

      <S.FormRow3 className="first-larger">
        <Input
          name="city"
          label="City"
          reqd={true}
          autocomplete="address-level2"
          update={update}
          errors={errors}
        />

        <Input
          name="st"
          label="State"
          reqd={true}
          autocomplete="address-level1"
          update={update}
          errors={errors}
        />

        <Input
          name="zip"
          label="Zip Code"
          reqd={true}
          autocomplete="postal-code"
          update={update}
          errors={errors}
        />
      </S.FormRow3>
    </Fragment>
  )
}

export default Shipping
