import styled from "styled-components"
import { Table as BaseTable } from "../../styles/styled"

let S = {}
S.H2 = styled.h2`
  margin-top: 15px;
  margin-bottom: 10px;
`

S.Table = styled(BaseTable)`
  & .price {
    width: 15%;
  }

  & .item {
    padding: 7.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;

      & span {
        font-size: 0.9rem;
      }

      & ul {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        margin-left: 30px;
        font-size: 0.85rem;
      }
    }
  }

  & .total th,
  & .total td {
    text-transform: uppercase;
    font-size: 1.05rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`
export default S
