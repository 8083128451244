import React, { Fragment } from "react"
import Input from "../../components/Input"
import S from "./styled"
import { FormRow3 } from "../../styles/styled"

S.FormRow3 = FormRow3

const Payment = ({ update, errors }) => {
  return (
    <Fragment>
      <S.H2>Payment</S.H2>

      <S.FormRow3 style={{ marginTop: "5px" }}>
        <Input
          type="text-secure"
          name="ccard"
          label="Credit Card Number"
          reqd={true}
          autocomplete="cc-number"
          update={update}
          errors={errors}
        />

        <Input
          type="text-secure"
          name="expdate"
          label="Exp. Date (mmyy)"
          reqd={true}
          autocomplete="cc-exp"
          update={update}
          errors={errors}
        />

        <Input
          type="text-secure"
          name="cvv"
          label="CVV/CID"
          reqd={true}
          autocomplete="cc-csc"
          update={update}
          errors={errors}
        />
      </S.FormRow3>
    </Fragment>
  )
}

export default Payment
