import React, { Fragment } from "react"
import Input from "../../components/Input"
import Checkbox from "../../components/Checkbox"
import S from "./styled"
import { FormRow1, FormRow2 } from "../../styles/styled"

S.FormRow1 = FormRow1
S.FormRow2 = FormRow2

const Contact = ({ member, update, same, errors }) => {
  return (
    <Fragment>
      <S.H2>Contact Information</S.H2>

      {member.number && !same && (
        <S.FormRow1 style={{ marginTop: "5px", marginBottom: "7.5px" }}>
          <Checkbox
            name="same"
            label="Check to use your Member details"
            reqd={false}
            update={update}
            errors={errors}
          />
        </S.FormRow1>
      )}

      <S.FormRow2>
        <Input
          name="fname"
          label="First Name"
          reqd={true}
          autocomplete="given-name"
          update={update}
          errors={errors}
        />

        <Input
          name="lname"
          label="Last Name"
          reqd={true}
          autocomplete="family-name"
          update={update}
          errors={errors}
        />
      </S.FormRow2>

      <S.FormRow2>
        <Input
          name="email"
          label="Email"
          reqd={true}
          autocomplete="email"
          update={update}
          errors={errors}
        />

        <Input
          name="phone"
          label="Phone"
          reqd={true}
          autocomplete="tel"
          update={update}
          errors={errors}
        />
      </S.FormRow2>

      <S.FormRow1>
        <Input
          name="firm"
          label="Firm Name"
          reqd={false}
          autocomplete="off"
          update={update}
          errors={errors}
        />
      </S.FormRow1>
    </Fragment>
  )
}

export default Contact
