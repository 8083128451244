import React, { useState, useEffect, useRef, Fragment } from "react"
import { navigate } from "gatsby"
import Script from "react-load-script"
import {
  API_URL,
  AUTHNET_SCRIPT,
  AUTHNET_LOGINID,
  AUTHNET_PUBKEY,
  getValFromLS,
  setValToLS,
} from "../utils"
import Helmet from "../components/Helmet"
import Hdr from "../components/Hdr"
import Contact from "../pagesReview/Contact"
import Shipping from "../pagesReview/Shipping"
import Order from "../pagesReview/Order"
import Payment from "../pagesReview/Payment"
import Msg from "../components/Msg"
import Submit from "../components/Submit"
import { Form, FormMsgSubmit } from "../styles/styled"
import Ftr from "../components/Ftr"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

let S = {}
S.Form = Form
S.FormMsgSubmit = FormMsgSubmit

function hasCdDvd(array) {
  let cdDvd = false
  array.forEach(item => {
    if (item.format === "cd" || item.format === "dvd") {
      cdDvd = true
    }
  })
  return cdDvd
}

const ReviewPage = () => {
  const [signedin, setSignedin] = useState({})
  const [items, setItems] = useState([])
  const [status, setStatus] = useState(2)
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  useEffect(() => {
    if (getValFromLS("member", true)) {
      setSignedin(JSON.parse(getValFromLS("member", true)))
    }

    if (getValFromLS("order", true)) {
      setItems(JSON.parse(getValFromLS("order", true)))
      setStatus(1)
    } else {
      setStatus(0)
    }
  }, [])

  const handleUpdate = (name, value) => {
    if (name === "same") {
      formElement.current.fname.value = signedin.fname
      formElement.current.email.value = signedin.email
      formElement.current.lname.value = signedin.lname
      setNames(names => ({
        ...names,
        same: value,
        fname: signedin.fname,
        lname: signedin.lname,
        email: signedin.email,
      }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    if (!names.ccard) {
      setErrors(["ccard"])
      setMsg({
        type: "error",
        text: "Please enter a valid credit card number.",
      })
      return false
    } else if (!names.expdate) {
      setErrors(["expdate"])
      setMsg({
        type: "error",
        text: "Please enter a valid expiration date.",
      })
      return false
    } else if (names.expdate.trim().replace(/\//g, "").length !== 4) {
      setErrors(["expdate"])
      setMsg({
        type: "error",
        text: "Please enter exactly 4 numbers (mmyy) only for credit card expiration date.",
      })
      return false
    } else if (!names.cvv) {
      setErrors(["cvv"])
      setMsg({
        type: "error",
        text: "Please enter a valid credit card CVV code.",
      })
      return false
    }

    const url = new URL(`${API_URL}/review`)
    const body = {
      member: getValFromLS("member", true)
        ? JSON.parse(getValFromLS("member", true))
        : {},
      fname: names.fname || "",
      lname: names.lname || "",
      email: names.email || "",
      phone: names.phone || "",
      firm: names.firm || "",
      hasCdDvd: hasCdDvd(items),
      address: names.address || "",
      address2: names.address2 || "",
      city: names.city || "",
      st: names.st || "",
      zip: names.zip || "",
      order: getValFromLS("order", true)
        ? JSON.parse(getValFromLS("order", true))
        : [],
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        const authnetData = {
          apiLoginID: AUTHNET_LOGINID,
          clientKey: AUTHNET_PUBKEY,
        }
        const authnetCard = {
          cardNumber: names.ccard.replace(/-|\s/g, ""),
          month: names.expdate.substring(0, 2),
          year: names.expdate.substring(2, 4),
          cardCode: names.cvv,
          fullName: `${names.fname} ${names.lname}`,
        }
        window.Accept.dispatchData(
          { authData: authnetData, cardData: authnetCard },
          handleAuthnetResponse
        )
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleAuthnetResponse = response => {
    if (response.messages.resultCode === "Error") {
      //console.log(response.messages.message);
      setMsg({
        type: "error",
        text: `An error has occurred, (${response.messages.message[0].code}) ${response.messages.message[0].text}`,
      })
    } else {
      //console.log(response.opaqueData);
      handleSuccess(response.opaqueData)
    }
  }

  const handleSuccess = async opaqueData => {
    const url = new URL(`${API_URL}/submit`)
    const body = {
      member: getValFromLS("member", true)
        ? JSON.parse(getValFromLS("member", true))
        : {},
      fname: names.fname || "",
      lname: names.lname || "",
      email: names.email || "",
      phone: names.phone || "",
      firm: names.firm || "",
      address: names.address || "",
      address2: names.address2 || "",
      city: names.city || "",
      st: names.st || "",
      zip: names.zip || "",
      order: JSON.parse(getValFromLS("order", true)),
      opaqueData,
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        const complete = {
          id: json.id,
          contact: json.contact,
          shipping: json.shipping,
          order: json.order,
          totals: json.totals,
          payment: json.payment,
        }
        setValToLS("complete", JSON.stringify(complete), true)
        setValToLS("order", null, true)
        setValToLS("member", null, true)
        navigate("/complete")
      } else {
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleScriptCreate = () => {
    handleUpdate("scriptLoaded", false)
  }

  const handleScriptError = () => {
    setMsg({
      type: "error",
      text: "An error occurred loading the payment gateway.",
    })
  }

  const handleScriptLoad = () => {
    handleUpdate("scriptLoaded", true)
  }

  return (
    <Fragment>
      <Helmet
        title="Order Review"
        descr="The Palm Beach County Bar Association (PBCBA) offers CLE hours from the sale of audio MP3’s, MP4’s and CD’s recorded at previously held live seminars of area bar associations."
      />
      <Hdr />
      {status === 1 ? (
        <Fragment>
          <Script
            url={AUTHNET_SCRIPT}
            onCreate={handleScriptCreate}
            onError={handleScriptError}
            onLoad={handleScriptLoad}
          />
          <S.Form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            ref={formElement}
            style={{
              paddingLeft: "25px",
              paddingRight: "25px",
              marginBottom: "25px",
            }}
          >
            <Contact
              member={signedin}
              update={handleUpdate}
              same={names.same ? true : false}
              errors={errors}
            />
            {hasCdDvd(items) && (
              <Shipping update={handleUpdate} errors={errors} />
            )}
            <Order member={signedin} items={items} />
            <Payment update={handleUpdate} errors={errors} />
            <S.FormMsgSubmit>
              {msg.type && <Msg data={msg} />}
              {msg.type !== "working" && (
                <Submit
                  name="Submit Order & Payment"
                  icon={faChevronCircleRight}
                />
              )}
            </S.FormMsgSubmit>
          </S.Form>
        </Fragment>
      ) : status === 0 ? (
        <div>No items in cart</div>
      ) : null}
      <Ftr />
    </Fragment>
  )
}

export default ReviewPage
