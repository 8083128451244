import React, { Fragment } from "react"
import { fmtCurrency } from "../../utils"
import S from "./styled"

const Order = ({ member, items }) => {
  let shipping = 0
  let discount = 0
  let subtotal = 0

  return (
    <Fragment>
      <S.H2>Order Details</S.H2>

      <S.Table>
        <thead>
          <tr>
            <th className="left">Course</th>
            <th>Format</th>
            <th className="price">{member.number ? "Member" : "Non Member"}</th>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => {
            if (item.format === "cd" || item.format === "dvd") {
              shipping = 1000
            }
            const amt = member.number ? item.member : item.nonmember
            subtotal = subtotal + amt
            /* discount = member.number ? discount + amt * 0.25 : 0 */

            return (
              <tr key={index}>
                <td>
                  <div className="item">
                    <div>
                      <span>{item.name}</span>
                    </div>
                  </div>
                </td>
                <td className="center">{item.format.toUpperCase()}</td>
                <td className="right">{fmtCurrency(amt / 100)}</td>
              </tr>
            )
          })}
        </tbody>

        <tfoot>
          <tr>
            <th className="right" colSpan="2">
              Subtotal
            </th>
            <td className="right">{fmtCurrency(subtotal / 100)}</td>
          </tr>

          {/*member.number && (
            <tr>
              <th className="right" colSpan="2">
                25% Off Courses (7/15 - 8/15)
              </th>
              <td className="right">
                <span style={{ color: "var(--error)" }}>
                  ({fmtCurrency(discount / 100)})
                </span>
              </td>
            </tr>
          )*/}

          <tr>
            <th className="right" colSpan="2">
              Shipping
            </th>
            <td className="right">{fmtCurrency(shipping / 100)}</td>
          </tr>

          <tr className="total">
            <th className="right" colSpan="2">
              Total
            </th>
            <td className="right">
              {fmtCurrency((subtotal - discount + shipping) / 100)}
            </td>
          </tr>
        </tfoot>
      </S.Table>
    </Fragment>
  )
}

export default Order
